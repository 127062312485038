<template>
  <div>
    <!-- <div id="navImpersonator" class="w-100 flex-grow-1">
      <div class="h-100 py-4 container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div id="wideBorder" class="card shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary">
              <div class="card-body p-5">
                <h1 class="h2 mb-5 font-weight-bold text-center d-flex align-items-center justify-content-center">
                  <Logo :fill="'#212529'" :height="'40px'" :width="'40px'" class="mb-0 mr-2 d-flex align-items-center">
                  </Logo>
                  Trademaster
                </h1>
                <div class="alert alert-danger form-group" v-if="fehlermeldung !== ''">
                  {{ fehlermeldung }}
                </div>
                <form @submit.prevent="anmelden" class="needs-validation" novalidate>
                  <div class="mb-4 form-group d-flex flex-column">
                    <label for="email">{{ $t("anAbmelden.email") }}</label>
                    <input id="email" type="email" class="form-control" name="email" required autofocus
                      v-model="email" />
                  </div>
                  <div class="mb-4 form-group d-flex flex-column">
                    <label for="password" class="">{{ $t("anAbmelden.passwort") }}</label>
                    <input id="password" type="password" class="form-control" name="password" required
                      v-model="passwort" />
                  </div>
                  <button class="w-100 btn btn-primary mt-2" type="submit"
                    :disabled="email === '' || passwort === ''">{{ $t("anAbmelden.anmelden") }}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
//Vue Components
// import Logo from '../assets/Logo.vue'

import { auth } from "../firebase"
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"

export default {
  name: 'AnmeldenView',
  /* components: {
    Logo
  }, */
  data() {
    return {
      email: '',
      passwort: '',
      fehlermeldung: ''
    }
  },
  methods: {
    anmelden: function () {
      if (this.email === '' || this.passwort === '') {
        return
      }

      const emailRegExp = RegExp('^[a-z.-]+@comline-shop(.[a-z]+){1,2}$')

      if (emailRegExp.test(this.email) === false) {
        this.fehlermeldung = this.$t("anAbmelden.ungeueltigeEmail")
        return
      } else if (this.passwort === '') {
        this.fehlermeldung = this.$t("anAbmelden.keinPasswort")
        return
      }

      signInWithEmailAndPassword(auth, this.email, this.passwort)
      .then(() => this.$router.replace('uebersicht'))
      .catch(error => {
        if (error.code === 'auth/wrong-password') this.fehlermeldung = this.$t("anAbmelden.ungeueltigesPasswort")
        else if (error.code === 'auth/user-not-found') this.fehlermeldung = this.$t("anAbmelden.benutzerNichtGefunden")
        else if (error.code === 'auth/user-disabled') this.fehlermeldung = this.$t("anAbmelden.benutzerDeaktiviert")
        else this.fehlermeldung = error.message
      })
      
    }
  },
  mounted() {
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
      .then(()=>{
        this.$router.push({name: 'Uebersicht'})
      })
      .catch(err=>{console.error(err)})
  }
}
</script>

<style scoped>
#wideBorder {
  border-top-width: 5px !important;
}

#navImpersonator {
  margin-top: 37px
}
h1 {
  color: #212529 !important;
}
</style>

